import { asRouteMap } from '@kaliber/routing'

import { getRouteData } from './routeData'

const routeData = getRouteData()

export const routeMap = asRouteMap(
  {
    root: '',
    api: {
      path: 'api',

      v1: {
        path: 'v1',

        woodwing: {
          path: 'woodwing',

          publish: {
            path: 'publish',
            data: requestHandlers => requestHandlers.woodwingPublish,
          },
        },

        postcode: {
          path: 'postcode',
          data: requestHandlers => requestHandlers.resolveLinkForPostcode
        },

        cookieConfiguration: {
          path: 'consents',
          data: requestHandlers => requestHandlers.setCookieConfiguration,
        },

        search: {
          path: 'search',
          data: requestHandlers => requestHandlers.search,

          createSpecial: {
            path: 'special',
            data: requestHandlers => requestHandlers.createSpecialSearch,
          }
        },

        searchWithExcludedRegions: {
          path: 'searchWithExcludedRegions',
          data: requestHandlers => requestHandlers.searchWithExcludedRegions
        },

        getTags: {
          path: 'getTags',
          data: requestHandlers => requestHandlers.getTags
        },

        getRubrics: {
          path: 'getRubrics',
          data: requestHandlers => requestHandlers.getRubrics
        }
      },

      sitemap: {
        path: 'sitemap',
        data: requestHandlers => requestHandlers.sitemap,
      },

      notFound: '*'
    },
    preview: 'preview',
    admin: 'admin',
    app: {
      path: ':language',
      data: routeData.app.data,

      home: {
        path: '',
        data: routeData.app.home
      },

      articles: {
        path: 'articles',

        overview: {
          path: '',
          data: routeData.app.articlesOverview
        },

        sanityResearchArticle: {
          path: 'research/:slug',
          data: routeData.app.sanityResearchCanonical
        },

        specialIntroduction: {
          path: 'special/:slug',
          data: routeData.app.specialIntroductionCanonical
        },

        single: {
          path: ':idOrIds',
          data: routeData.app.articleDetailCanonical
        },
      },

      issue: {
        path: ':issue',
        data: routeData.app.issue,
        region: {
          path: ':bankcode',
          data: routeData.app.articleOverview,
          article: {
            path: ':rubricSlug',
            data: routeData.app.articleDetail
          },
        }
      },

      specials: {
        path: 'specials',
        special: {
          path: ':specialSlug',
          data: routeData.app.specialOverview,
          article: {
            path: ':idOrIds/:specialArticleSlug',
            data: routeData.app.specialArticleDetail
          },
        }
      },

      redactie: {
        path: 'redactie',
        data: routeData.app.redactie
      },

      cookiePolicy: {
        path: 'cookie-beleid',
        data: routeData.app.cookiePolicy
      },

      notFound: {
        path: '*',
        data: routeData.app.notFound
      },
    }
  },
  { trailingSlash: true }
)
