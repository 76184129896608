import groq from 'groq'
import { toPlainText } from '@portabletext/react'
import { extract, language, slug } from '@kaliber/sanity-routing/src/parameterExtraction'

import { NOT_FOUND } from '/machinery/statusCodes'
import { cookieConfiguration } from '/machinery/tracking/cookieConfiguration'

import { extractArticles } from '/features/regionArticles/extractArticles'

import { image } from '/placeholder-data/placeholder-helpers'

import { routeMap } from './routeMap'

export function getRouteData() {
  return {
    app: {
      data: {
        groq: ({ params: { language } }) => ({
          specials: [
            groq`*[_type == 'special' && language == $language] {
              title, 'image': cover, slug, language
            }`,
            { language }
          ]
        }),
        fetch: {
          cookieConfiguration: requestHandlers => requestHandlers.getCookieConfiguration,
          platformReleaseDate: requestHandlers => requestHandlers.getPlatformReleaseData
        },
        derived: ({ data, params }) => ({
          specials: data.specials.map(x => ({
            ...x,
            href: routeMap.app.specials.special({ language: x.language, specialSlug: x.slug.current })
          })),
          dataLayer: {
            ...resolveCookieSettingsForDataLayer({ data }),
            metadata: {
              platform: {
                releasedate: data.platformReleaseDate
              },
              content: {
                language: params.language,
              }
            }
          }
        }),
      },

      home: {
        fetch: {
          doc: requestHandlers => requestHandlers.getHomeData,
          issues: requestHandlers => requestHandlers.getIssuesForHome,
        },
        derived: ({ derived, data }) => deriveIssueData('frontpage', { derived, data }, { title: 'Kies jouw regio' })
      },

      articlesOverview: {
        fetch: {
          searchResults: requestHandlers => requestHandlers.search,
          heroImage: requestHandlers => requestHandlers.getCurrentIssueImage,
        },
        derived: ({ derived, data }) => deriveIssueData('articlesOverview', { derived, data }, { title: 'Zoek in alle artikelen' })
      },

      issue: {
        fetch: {
          doc: requestHandlers => requestHandlers.getIssueData,
          issues: requestHandlers => requestHandlers.getIssuesForIssuePage,
        },
        derived: ({ derived, data }) => deriveIssueData('region-picker', { derived, data }, { title: 'Lees deze uitgave' })
      },

      specialOverview: {
        fetch: {
          feedData: requestHandlers => requestHandlers.getFeedDataForSpecial,
          issues: requestHandlers => requestHandlers.getIssuesForIssuePage,
        },
        derived: ({ data, derived, params }) => deriveSpecialArticleOverviewData({ data, derived, params }),
        extractParams: {
          special: extract(language, (doc) => ({ specialSlug: doc.slug.current }))
        },
      },

      specialArticleDetail: {
        fetch: {
          doc: requestHandlers => requestHandlers.getArticleData,
        },
        derived: ({ data, derived, params }) => {
          const id = data.doc?.id
          if (data?.doc?.status === NOT_FOUND)
            return { status: NOT_FOUND, ...deriveNotFoundData({ data, derived }) }

          return {
            canonical: id ? routeMap.app.articles.single({ language: params.language, idOrIds: id }) : null,
            doc: addSeoDataToArticle(data.doc),
            dataLayer: getDataLayerForSpecialArticleDetail({ name: data.feedData?.title, derived, data })
          }
        }
      },

      articleDetail: {
        fetch: {
          doc: requestHandlers => requestHandlers.getPageData,
          issues: requestHandlers => requestHandlers.getIssuesWithinRegion,
        },
        derived: ({ data, derived, params }) => {
          const id = data.doc?.id

          return {
            canonical: id ? routeMap.app.articles.single({ language: params.language, idOrIds: id }) : null,
            doc: addSeoDataToArticle(data.doc),
            dataLayer: getDataLayerForArticleDetail({ derived, data })
          }
        }
      },

      articleOverview: {
        fetch: {
          feedData: requestHandlers => requestHandlers.getFeedDataForRegion,
          issues: requestHandlers => requestHandlers.getIssuesWithinRegion,
        },
        derived: ({ data, derived, params }) => deriveArticleOverviewData({ data, derived, params })
      },

      articleDetailCanonical: {
        fetch: {
          cover: requestHandlers => requestHandlers.getCover,
          relatedArticles: requestHandlers => requestHandlers.getRelatedArticlesForSubject,
          searchResults: requestHandlers => requestHandlers.search,
          doc: requestHandlers => requestHandlers.getArticleData,
        },
        derived: ({ data, derived, params }) => {
          if (data?.doc?.status === NOT_FOUND)
            return { status: NOT_FOUND, ...deriveNotFoundData({ data, derived }) }

          const id = data.doc?.id

          return {
            canonical: id ? routeMap.app.articles.single({ language: params.language, idOrIds: id }) : null,
            doc: addSeoDataToArticle(data.doc),
            dataLayer: getDataLayerForArticleDetail({ derived, data })
          }
        },
      },

      sanityResearchCanonical: {
        groq: ({ params: { slug, language } }) => ({
          doc: [`*[_type == $type && slug.current == $slug && language == $language][0]{..., tags[]->, issue-> }`, { type: 'research', slug, language }]
        }),

        derived: ({ data, derived, params: { language, slug } }) => {
          if (!data?.doc)
            return { status: NOT_FOUND, ...deriveNotFoundData({ data, derived }) }

          const rubric = {
            name: 'RaboResearch',
            slug: 'raboresearch'
          }

          const mappedDoc = mapSanityDoc(data.doc, {
            template: 'kaliber_research',
            type: 'research',
            rubric,
            extractIssueCode: issue => issue.issue_code,
          })
          const { id  } = mappedDoc

          return {
            canonical: id ? routeMap.app.articles.single({ language, idOrIds: id }) : null,
            doc: addSeoDataToArticle(mappedDoc),
            dataLayer: getDataLayerForArticleDetail({ derived, data: { ...data, doc: mappedDoc } })
          }
        },
        extractParams: { 'research': extract(language, slug) },
      },

      specialIntroductionCanonical: {
        groq: ({ params: { slug, language } }) => ({
          doc: [`*[_type == $type && slug.current == $slug && language == $language][0]{..., tags[]->, issue-> }`, { type: 'specialIntroduction', slug, language }]
        }),

        derived: ({ data, derived, params: { language } }) => {
          if (!data?.doc)
            return { status: NOT_FOUND, ...deriveNotFoundData({ data, derived }) }

          const rubric = {
            name: 'Voorwoord',
            slug: 'voorwoord'
          }

          const mappedDoc = mapSanityDoc(data.doc, {
            template: 'kaliber_article',
            type: 'specialIntroduction',
            rubric,
            extractIssueCode: issue => issue.slug.current,
          })
          const { id } = mappedDoc

          return {
            canonical: id ? routeMap.app.articles.single({ language, idOrIds: id }) : null,
            doc: addSeoDataToArticle(mappedDoc),
            dataLayer: getDataLayerForSpecialArticleDetail({ name: rubric.name, derived, data: { ...data, doc: mappedDoc } })
          }
        },
        extractParams: { 'specialIntroduction': extract(language, slug) },
      }
    },

    cookiePolicy: {
      derived: ({ derived, data }) => deriveCookiePolicyData({ derived, data }),
    },

    redactie: {
      derived: ({ derived, data }) => deriveRedactieData({ derived, data })
    },

    notFound: {
      derived: ({ derived, data }) => deriveNotFoundData({ derived, data })
    }
  }
}

function deriveIssueData(type, { derived, data }, { title }) {
  if (data?.doc?.status === NOT_FOUND)
    return { status: NOT_FOUND, ...deriveNotFoundData({ data, derived }) }

  return {
    ...derived,
    doc: {
      ...data.doc,
      seo: {
        title,
        description: 'Rabo &Co is ons ledenmagazine. In woord en beeld staan we stil bij bijzondere mensen en initiatieven in jouw regio.',
        social: {
          shareImage: defaultShareImage()
        }
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type
        }
      }
    }
  }
}

function deriveArticleOverviewData({ data, derived, params }) {
  return {
    doc: {
      ...data.doc,
      seo: {
        title: data.feedData?.title,
        description: `Bekijk alle artikelen in de regio ${data.feedData?.title}`,
        social: {
          shareImage: getShareImageForDeckfeed({ data })
        }
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        rabo: {
          bankcode: data.feedData?.bank.bankcode,
          bankname: toLowerCase(data.feedData?.bank.naam),
          edition: params.issue,
          kring: data.feedData?.bank.kringnummer,
        },
        template: {
          type: 'article-overview'
        }
      }
    }
  }
}

function deriveSpecialArticleOverviewData({ data, derived, params }) {
  return {
    doc: {
      ...data.doc,
      seo: {
        title: data.feedData?.title,
        description: `Bekijk alle artikelen in onze special ${data.feedData?.title}`,
        social: {
          shareImage: getShareImageForDeckfeed({ data })
        }
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer?.metadata,
        content: {
          special: {
            name: data.feedData?.title,
          }
        },
        rabo: {
          bankcode: data.feedData?.bank.bankcode,
          bankname: toLowerCase(data.feedData?.bank.naam),
          edition: params.issue,
          kring: data.feedData?.bank.kringnummer,
        },
        template: {
          type: 'article-overview'
        }
      }
    }
  }
}

function deriveRedactieData({ derived, data }) {
  return {
    ...derived,
    doc: {
      ...derived.doc,
      ...data.doc,
      seo: {
        title: 'Redactie',
        social: defaultSocialShareImage(),
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type: 'redactie'
        }
      }
    }
  }
}

function deriveCookiePolicyData({ derived, data }) {
  return {
    ...derived,
    doc: {
      ...data.doc,
      seo: {
        title: 'Privacy en cookies',
        social: defaultSocialShareImage(),
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type: 'cookie-beleid'
        }
      }
    }
  }
}

function deriveNotFoundData({ derived, data }) {
  return {
    ...derived,
    doc: {
      ...data.doc,
      seo: {
        title: 'Pagina niet gevonden',
        social: defaultSocialShareImage(),
      }
    },
    dataLayer: {
      ...derived.dataLayer,
      metadata: {
        ...derived.dataLayer.metadata,
        template: {
          type: '404'
        }
      }
    }
  }
}

function getDataLayerForSpecialArticleDetail({ name, derived, data }) {
  const specialsContentData = {
    dataLayer: {
      metadata: {
        content: {
          specials: { name }
        }
      }
    }
  }

  return getDataLayerForArticleDetail({ derived: { ...derived, specialsContentData }, data })
}

function getDataLayerForArticleDetail({ derived, data }) {
  return {
    ...derived.dataLayer,
    metadata: {
      ...derived.dataLayer?.metadata,
      content: {
        ...derived.dataLayer?.metadata?.content,
        id: data.doc?.id,
        author: data.doc?.metadata?.credits?.author,
        datecreated: data.doc?.dataLayer?.datecreated || undefined,
        dateupdated: data.doc?.dataLayer?.dateupdated || undefined,
        language: 'nl',
        region: toLowerCase(data.feedData?.bank.regio),
        tag: data.doc?.metadata.tags || undefined,
        title: toLowerCase(data.doc?.content?.hero?.title),
        type: data.doc?.metadata.template,
        readtime: data.doc?.metadata.readTime || 0,
        wordcount: data.doc?.dataLayer?.wordcount || 0,
        charscount: data.doc?.dataLayer?.charscount || 0,
        parascount: data.doc?.dataLayer?.parascount || 0,
        feedindex: data.feedData?.feedItems && data.doc ? determineFeedIndex(data.feedData?.feedItems) : undefined,
        rubric: toLowerCase(data.doc?.metadata.rubric.name),
      },
      template: {
        ...derived.dataLayer?.metadata?.template,
        type: 'article',
      },
      rabo: {
        ...derived.dataLayer?.metadata.rabo,
        rubric: toLowerCase(data.doc?.metadata.rubric.name),
        edition: derived.doc?.issue
      }
    }
  }

  function determineFeedIndex(feedItems) {
    const articles = extractArticles(feedItems)

    return [
      articles.findIndex((x) => x.id === data.doc.id) + 1,
      extractArticles(feedItems).length
    ].join('_')
  }
}

function toLowerCase(string) {
  return string && string.toLowerCase()
}

function resolveCookieSettingsForDataLayer({ data }) {
  return {
    ...cookieConfiguration(data?.cookieConfiguration?.configuration ?? null).cookieConfiguration,
    personalization_storage: 'denied',
    security_storage: 'granted',
  }
}

function getShareImageForDeckfeed({ data }) {
  const [firstItem] = data.feedData?.feedItems || []

  if (!firstItem)
    return defaultShareImage()

  const shareImage = firstItem.metadata.template === 'hero'
    ? firstItem.content.hero.image
    : defaultShareImage()

  return shareImage
}

function defaultSocialShareImage() {
  return {
    shareImage: defaultShareImage()
  }
}

function defaultShareImage() {
  return image({ src: 'image-clp6tyg4b0000dnb66khf65z4-1200x675-webp', host: 'https://raboenco.rabobank.nl' })
}

function addSeoDataToArticle(doc) {
  if (!doc)
    return null

  return {
    ...doc,
    seo: {
      ...doc?.metadata?.seo,
      title: doc?.metadata?.seo?.title || doc?.content?.hero?.title,
      social: {
        shareImage: doc?.content?.hero?.image || defaultShareImage()
      }
    }
  }
}

function mapSanityDoc(doc, { type, template, rubric, extractIssueCode }) {
  const { slug, hero, title, credits, tags: rawTags, issue, content = [], seo, _updatedAt, _createdAt } = doc

  const tags = rawTags?.map(x => x.title) || []
  const plainContent = toPlainText(content)

  const mapped = {
    type,
    id: slug.current,
    slug: slug.current,
    publicationGroupId: slug.current,
    publicationLevelName: 'Rabo en Co Centraal',
    issue: extractIssueCode(issue),
    issueTitle: issue.title,
    content: {
      hero,
      items: content
    },
    hero,
    searchPlainText: {
      title: title || hero.title,
      seoTitle: seo.title || title || hero.title,
      introduction: plainContent,
      tags
    },
    metadata: {
      credits,
      rubric,
      template,
      readTime: getReadTime(plainContent, { wordsPerMinute: 225 }),
      tags,
      seo: {
        ...seo,
        description: seo.description || plainContent
      }
    },
    dataLayer: {
      datecreated: _createdAt,
      dateupdated: _updatedAt,
      wordcount: plainContent.split(' ').length,
      charscount: plainContent.length,
      parascount: content.filter(x => x._type === 'block').length
    }
  }

  return mapped
}

export function getReadTime(plainContent, { wordsPerMinute }) {

  if (!plainContent)
    return 0

  const words = plainContent.split(/\s+/).filter(word => word.length > 0)
  return Math.ceil(words.length / wordsPerMinute)
}
